html {
  overscroll-behavior: none;
}
body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: 'Inter' font-semibold;
}

#root {
  display: flex;
  height: 100%;
}

/** Update Scrollbar UI */
::-webkit-scrollbar {
  height: 1rem;
  width: 0.3rem;
}

::-webkit-scrollbar:horizontal {
  height: 0.5rem;
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(217, 217, 227, 0.8);
  /* background-color: rgba(83, 82, 82, 0.5); */

  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 5px;
  border-width: 1px;
}

::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 82, 82, var(--tw-bg-opacity));
}
